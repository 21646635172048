import { FC } from 'react';
import IconProps from './IconProps';

const SyncIcon: FC<IconProps> = (props) => {
  const { onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  return (
    <svg onClick={onClick} className={`inline ${cursor} ${className}`} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.5 6.48438H9.5C5.78672 6.48438 3 9.1694 3 12.9844"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.5 19.4844H14.5C18.2133 19.4844 21 16.7994 21 12.9844"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.5 3.98438C18.5 3.98438 21 5.8256 21 6.4844C21 7.1432 18.5 8.98438 18.5 8.98438"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.49998 16.9844C5.49998 16.9844 3.00001 18.8256 3 19.4844C2.99999 20.1432 5.5 21.9844 5.5 21.9844"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SyncIcon;
